import React from "react";
import AppointmentSection from "./AppointmentSection";

function Dashboard() {
  return (
    <>
      <AppointmentSection />
    </>
  );
}

export default Dashboard;
